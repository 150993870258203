$(document).ready(function(){

  $('.m-menu').click(function(){
    $('html, body').toggleClass('noscroll');
    $('body').toggleClass('m-menu-open');
  });

  var audio = new Audio();
  var audioPlaying = false;

  $('.c-case .play').click(function(){
    var audioSrc = $(this).attr('data-src');
    if (audioPlaying) {
      audio.pause();
      audioPlaying = false;
    }else{
      audio = new Audio(audioSrc);
      audio.play();
      audioPlaying = true;
      audio.onended = function(){
        audioPlaying = false;
      }
    }
  })

});